<template>
    <div>
        <div class="flex justify-end">
            <Button outline text="RECORD OFF WALLET TX" class="mr-2" @click="showMakePaymentForOffWallet('record')"/>
            <Button outline text="MAKE OFF WALLET TX" class="mr-2" @click="showMakePaymentForOffWallet('make')"/>
            <Button text="Initiate payment" @click="showMakePayment = true" v-if="accountData.businessKybDetails && accountData.businessKybDetails.kycStatus == 'APPROVED'    && getUserPermissions && getUserPermissions.includes($completePermissionFormat('wire_payment_create'))        "/>
        </div>
        <Card class="mt-3">
            <div class="flex justify-center" v-if="loading">
                <Spinner/>
            </div>
            <div v-else>
                <div class="grid grid-cols-1 gap-4 md:grid-cols-2" v-if="accountData.wireAccount">
                    <div class="source">
                        <p class="text-lg font-semibold">Name : {{accountData.wireAccount.name}}</p>
                        <p class="mt-4">Status: {{accountData.wireAccount.forcedKycStatus}}</p>
                        <p class="mt-4">Broker Eligible Status: {{accountData.wireAccount.brokerAccessEligibilityStatus}}</p>
                        <p class="mt-4" v-if="accountData.wireAccount && accountData.wireAccount.brokerAccessStatus">Broker Access Status: {{accountData.wireAccount.brokerAccessStatus || 'NIL'}}</p>
                        <p class="mt-4" v-if="accountData.wireAccount && accountData.wireAccount.accountStatus">Wire account Status: {{accountData.wireAccount.accountStatus|| 'NIL'}}</p>
                        <div class="flex  mt-4 items-center flex-wrap gap-2">
                            <SecondaryButton
                                v-if="accountData.wireAccount.accountStatus == 'ACTIVE' && (getUserPermissions && getUserPermissions.includes($completePermissionFormat('deactivate_wire_account')))"
                                text="De activate Acc."
                                background="#f0ad4e"
                                color="white"
                                @click="handleDeactivateAccount"
                                :loading="deactivateLoading"
                          />
                            <SecondaryButton
                                v-if="accountData.wireAccount.accountStatus == 'RE_ACTIVATION_REQUESTED' || accountData.wireAccount.accountStatus == 'DE_ACTIVATED' &&  (getUserPermissions && getUserPermissions.includes($completePermissionFormat('deactivate_wire_account')))"
                                text="Re activate Acc."
                                @click="handleActivateAccount"
                                :loading="deactivateLoading"
                          />

                          <SecondaryButton
                            v-if="accountData.wireAccount.accountStatus == 'ACTIVE' && (getUserPermissions && getUserPermissions.includes($completePermissionFormat('block_wire_account')))"
                            text="Block Acc."
                            background="#ff4444"
                            color="white"
                            @click="handleBlockAccount"
                            :loading="blockLoading"
                            />
                            <SecondaryButton
                                    v-if="accountData.wireAccount.accountStatus == 'BLOCKED' && (getUserPermissions && getUserPermissions.includes($completePermissionFormat('block_wire_account')))"
                                    text="Unblock Acc."
                                    @click="handleUnBlockAccount"
                                    :loading="unBlockLoading"
                            />
                        </div>
                        <div class="flex flex-wrap gap-2 mt-4">
                            <SecondaryButton outline text="See adjusted rate" @click="showAdjustedRate = true"/>
                            <SecondaryButton text="See beneficiary list" @click="showAccountBeneficiaryList = true"/>
                        </div>
                    </div>
                   <div class="md:pl-3" v-if="getUserPermissions && getUserPermissions.includes($completePermissionFormat('wire_wallet_view'))">
                    <p class="font-semibold mt-4" v-if="getUserPermissions && getUserPermissions.includes($completePermissionFormat('wire_wallet_view'))" >Wallet Balance: {{accountData.wallet.balance | formatMoney}}</p>
                    <!-- <p class="font-semibold" v-if="getUserPermissions && getUserPermissions.includes($completePermissionFormat('wire_wallet_view'))" >Wallet Balance: {{accountData.wallet.balance | formatMoney}}</p>
                    <p class="">Account name: {{accountData.wallet.accountName || 'N/A'}} </p>
                    <p class="flex items-center">Account number: {{accountData.wallet.accountNumber || 'N/A'}}</p>
                    <p class="flex items-center">Bank name: {{accountData.wallet.bankName || 'N/A'}}</p>
                    <div class="flex items-center gap-4 mt-4">
                        <SecondaryButton text="Copy account details" @click="handleCopyAcctDetails"/>
                        <SecondaryButton text="View Wallet history" @click="showWalletHistory = true"/>
                    </div> -->
                    <p class="font-semibold mb-2">Account Details</p>
                    <Card class="mt-4" width="w-96" p="p-0" v-if="accountData.wallet && accountData.wallet.topupAccounts">
                        <div class="p-3" ref="accountTabs" :class="{'border-style' : index !== accountData.wallet.topupAccounts.length - 1}" v-for="(wallet, index) in accountData.wallet.topupAccounts" :key="wallet.id" >
                          <div class="flex justify-between items-center cursor-pointer" @click.stop="handleToggleWallet(wallet.id, index)">
                            <p class="text-sm text-dark-left">{{ wallet.bankName|| "NIL" }}</p>
                            <i class="ri-arrow-down-s-line"></i>
                          </div>
                          <div :style="{display: index == 0 ? 'block' : 'none'}" :ref="wallet.id">
                            <div class="grid grid-cols-3 mt-3" >
                              <div class="col-span-2">
                                <div class="grid grid-cols-2 content-center">
                                  <p class="text-grey-6 text-xs">Account number</p>
                                  <p class="text-lg text-dark font-bold">
                                    {{ wallet.accountNumber || "NIL" }}
                                  </p>
                                </div>
                                <!-- <div class="grid grid-cols-2 content-center mt-2">
                                  <p class="text-grey-6 text-xs">Account name</p>
                  
                                  <p class="text-sm text-dark-left">{{ wallet.accountName || "NIL" }}</p>
                                </div> -->
                                  <div class="grid grid-cols-2 content-center mt-2">
                                    <p class="text-grey-6 text-xs">Account name</p>
                                    <p class="text-xs text-dark">{{ wallet.accountName  || "NIL" }}</p>
                                  </div>
                                </div>
                                  <div class="justify-self-end" @click.stop="handleCopyAcctDetails(wallet)">
                                    <p class="text-xs border py-1 px-2 rounded cursor-pointer text-dark">Copy</p>
                                  </div>
                               </div>
                                <Button :disabled="!wallet.accountNumber"  class="mt-4" text="I’VE DONE THE TRANSFER" :loading="loading" width="w-full" p="p-2 py-3"
                                @click.stop="updateWalletBalance" />
                            </div>
                            <!-- <Hr v-if="index !== wallets.topupAccounts.length - 1" class=""/> -->
                        </div>
                      </Card>
                    <div class="flex items-center gap-4 mt-4">
                        <!-- <SecondaryButton text="Copy account details" @click="handleCopyAcctDetails"/> -->
                        <SecondaryButton text="View Wallet history" @click="showWalletHistory = true"/>
                    </div>
                    <div v-if="accountData.wireAccount && accountData.wireAccount.brokerAccessStatus === 'APPROVED'">
                        <Hr/>
                        <p class="font-semibold">Broker Details:</p>
                        <div class="flex items-center" v-if="brokerBalance">
                            <p v-if="brokerBalance.referralCommissionWallet.currency">Referral Wallet: {{ brokerBalance.referralCommissionWallet.balance | formatMoney(brokerBalance.referralCommissionWallet.currency.sign)}}</p>
                            <p class="ml-4" v-if="brokerBalance.paymentCommissionWallet.currency">Payment Wallet: {{ brokerBalance.paymentCommissionWallet.balance | formatMoney(brokerBalance.paymentCommissionWallet.currency.sign)}}</p>
                        </div>
                        <div class="flex mt-4 items-center gap-2">
                            <SecondaryButton text="View Comissions" @click="showCommissionSidebar = true"/>
                            <SecondaryButton text="View Referrals" @click="showReferralList = true"/>
                            <SecondaryButton text="View Registered Senders" @click="showSenderInformation = true" v-if="accountData.wireAccount && accountData.wireAccount.brokerAccessStatus"/>
                        </div>
                    </div>
                   
                   </div>
                </div>
            </div>
        </Card>
        <KybDetails @updateKyb=" getAccountDetails()" :businessDirector="businessDirector" :accountData="accountData" class="mt-4" :businessRegistrationInfo="businessRegistrationInfo"/>
        <ReferralList :accountDetails="accountData" v-if="showReferralList" @close="showReferralList = false"/>
        <ReferralComission :accountDetails="accountData" v-if="showCommissionSidebar" @close="showCommissionSidebar = false"/>
        <WalletHistory :accountDetails="accountData" v-if="showWalletHistory" @close="showWalletHistory = false"/>
        <Senders :accountDetails="accountData" v-if="showSenderInformation" @close="showSenderInformation = false"/>
        <MakePayment :makePaymentTxType="makePaymentTxType" :wireAccountData="accountData" display v-if="showMakePayment" @close="showMakePayment = false; 
        $router.push({
            ...this.$route.params,
            query:{}
        })"/>
        <AdjustedRateHistory v-if="showAdjustedRate" @close="showAdjustedRate = false"/>
        <AccountBeneficiaryList v-if="showAccountBeneficiaryList" @close="showAccountBeneficiaryList = false"/>
    </div>
   
</template>
<script>
import {VIEW_ACCOUNT_DETAILS,GET_BROKER_WALLET_BALANCE, DEACTIVATE_ACCOUNT , REACTIVATE_ACCOUNT, BLOCK_ACCOUNT, UN_BLOCK_ACCOUNT, GET_RATE_ADJUSTMENT} from '@/utils/api/wire'
import {SecondaryButton, Button} from '@/UI/Button'
import Spinner from '@/UI/Spinner'
import file from '@/mixins/file'
import Hr from '@/UI/Hr'
import UpdateKyb from './UpdateKyb'
import RateDiscount from './RateDiscount'
import {mapActions} from 'vuex'
import {Modal, RightSidebar} from '@/UI/Modals'
import {Select} from '@/UI/Input'
import EnterBusinessInfo from './OTC/EnterBusinessInfo.vue'
import AddDirectorsDetails from './OTC/AddDirectorsDetails.vue'
import {Input} from '@/UI/Input'
import copy from '@/mixins/copy'
import KybDetails from './KybDetails.vue'
import {mapGetters} from 'vuex'

export default {
    components:{
        Spinner,
        SecondaryButton,
        Hr,
        UpdateKyb,
        RateDiscount,
        Modal,
        RightSidebar,
        Select,
        Button,
        ReferralList:() => import('./ReferralList.vue'),
        ReferralComission:() => import('./ReferralComission'),
        WalletHistory:() => import('./WalletHistory.vue'),
        MakePayment: () => import('./MakePayment'),
        Senders: () => import('./Senders.vue'),
        AdjustedRateHistory:() => import('./AdjustedRateHistory'),
        AccountBeneficiaryList:() => import('./AccountBeneficiaryList.vue'),
        EnterBusinessInfo,
        AddDirectorsDetails,
        Input,
        KybDetails
    },
    mixins:[file, copy],
    data:() => ({
        loading: false,
        businessRegistrationInfo:{},
        businessDirector:[],
        showBusinessInfo: false,
        businessInfoAdded: false,
        showAddDirective: false,
        accountData:{},
        brokerBalance:{
            referralCommissionWallet:{},
            paymentCommissionWallet:{}
        },
        showReferralList: false,
        showCommissionSidebar: false,
        showWalletHistory: false,
        showSenderInformation: false,
        showMakePayment: false,
        showDeclinedModalforKyb: false,
        declinedReason:'',
        declineLoading: false,
        declineType:'',
        directorId:'',
        deactivateLoading: false,
        unBlockLoading: false,
        blockLoading: false,
        showAdjustedRate: false,
        showAccountBeneficiaryList: false,
        makePaymentTxType: ''
    }),
    computed:{
        ...mapGetters('auth', ['getUserPermissions'])
    },
    methods:{
        ...mapActions('notification', ['showAlert']),
        ...mapActions('loading', ['setLoading']),
        ...mapActions('wire', ['getCurrencyMappings']),
        getAccountDetails(){
            this.loading = true
            VIEW_ACCOUNT_DETAILS(this.$route.params.id, this.$route.query.wireAccountId)
                .then(({data}) => {
                    
                this.businessRegistrationInfo = data.data.businessKybDetails.businessRegistrationInfo
                this.businessDirector= data.data.businessKybDetails.businessDirector
                this.accountData = data.data
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
      getBrokerWalletBalance(){
        this.brokerWalletLoading = true;
        GET_BROKER_WALLET_BALANCE(this.$route.params.id)
            .then(({data}) => {
                this.brokerBalance = data.data;
                this.brokerWalletLoading = false
            })
            .catch(() => {
                this.brokerWalletLoading = false
            })
      },
      handleAddBusinessInfo(){
        this.showBusinessInfo = false;
        this.showAlert({
            display: true,
            description:'Update successful',
            title:'Success',
            type: 'success'
        })
        this.getAccountDetails();
      },
      handleDeactivateAccount(allow = false){
        if(!allow){
            this.showAlert({
                    display: true,
                    description:'Are you sure you want to dectivate this account',
                    title:'Confirm',
                    type: '',
                    callback:() => this.handleDeactivateAccount(true),
                    buttonText:'Confirm'
            })
            return
        }
        this.deactivateLoading = true;
        DEACTIVATE_ACCOUNT(this.$route.params.id)
            .then(() => {
                this.deactivateLoading = false;
                this.showAlert({
                    display: true,
                    description:'Account deactivated',
                    title:'Success',
                    type: 'success'
                })
                this.getAccountDetails();
        }).catch(() => {
            this.deactivateLoading = false
        })
        
      },
      handleActivateAccount(allow){
        if(!allow){
            this.showAlert({
                    display: true,
                    description:'Are you sure you want to Activate this account',
                    title:'Confirm',
                    type: '',
                    callback:() => this.handleActivateAccount(true),
                    buttonText:'Confirm'
            })
            return
        }
        this.deactivateLoading = true;
        REACTIVATE_ACCOUNT(this.$route.params.id)
            .then(() => {
                this.deactivateLoading = false;
                this.showAlert({
                    display: true,
                    description:'Account activated',
                    title:'Success',
                    type: 'success',
                })
                this.getAccountDetails();
        }).catch(() => {
            this.deactivateLoading = false
        })
        
      },
      handleBlockAccount(allow){
        if(!allow){
            this.showAlert({
                    display: true,
                    description:'Are you sure you want to Block this account',
                    title:'Confirm',
                    type: '',
                    callback:() => this.handleBlockAccount(true),
                    buttonText:'Confirm'

            })
            return
        }
        this.blockLoading = true;
        BLOCK_ACCOUNT(this.$route.params.id)
            .then(() => {
                this.blockLoading = false;
                this.showAlert({
                    display: true,
                    description:'Account blocked',
                    title:'Success',
                    type: 'success'
                })
                this.getAccountDetails();
        }).catch(() => {
            this.blockLoading = false
        })
        
      },
      handleUnBlockAccount(allow){
        if(!allow){
            this.showAlert({
                    display: true,
                    description:'Are you sure you want to unblock this account',
                    title:'Confirm',
                    type: '',
                    callback:() => this.handleUnBlockAccount(true)
            })
            return
        }
        this.unBlockLoading = true;
            UN_BLOCK_ACCOUNT(this.$route.params.id)
            .then(() => {
                this.unBlockLoading = false;
                this.showAlert({
                    display: true,
                    description:'Account unblocked',
                    title:'Success',
                    type: 'success'
                })
                this.getAccountDetails();
        }).catch(() => {
            this.unBlockLoading = false
        })
        
      },
      handleShowDeclineModal(type){
        this.declineType = type;
        this.showDeclinedModalforKyb = true
      },
      handleCopyAcctDetails(wallet){
        let data = `
        Account name: ${wallet.accountName}

        Bank name : ${wallet.bankName}

        Account number : ${wallet.accountNumber}
       `
        this.copy(data)
      },
      handleToggleWallet(id, index){
        if(this.$refs[id][0]){
            if(this.$refs[id][0].style.display == 'none'){
            this.$refs[id][0].style.display = 'block'
            // block other open tabs
                this.$refs.accountTabs.forEach((element, elementIndex) => {
                if(elementIndex != index){
                    element.childNodes[1].style.display = 'none'
                }
                })

            }else{
            this.$refs[id][0].style.display = 'none'
            }
        }      
        },
        showMakePaymentForOffWallet(value){
            this.makePaymentTxType = value
            // this.$router.replace({
            //     ...this.$route.params,
            //     query:{
            //         ...this.$route.query,
            //         txType: value
            //     }
            // })
            this.showMakePayment = true
        }
    },
    mounted(){
        this.getAccountDetails();
        this.getBrokerWalletBalance();
        this.getCurrencyMappings();
    }
}
</script>
<style scoped>
.source{
    /* border-right: 1px solid rgb(242, 237, 253);*/
}
@media (min-width:768px ){
    .source{
        border-right: 1px solid rgb(242, 237, 253)   
    }
}
.border-style{
    border-bottom: 1px solid rgb(227 232 239 );
  }
</style>